@font-face {
    font-family: 'Poppins';
    src: url('../src/fonts/poppins-all-400-normal.woff') format('woff');
    font-display: swap;
}


@media print {
    #root {
        display: none;
    }

    #mini-Morgan-chat-widget {
        display: none;
    }
}

body {
    margin: 0;
    background-color: #f8f8f8;
    font-family: "Poppins" !important;
}

img {
    width: 100%;
}

.propertyInfoFormWrapper {
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #0E5D7C;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin: 0.75rem;
}

.propertyInfoFormHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
    background-color: #0E5D7C;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 1rem;
}

#root {
    overflow-x: hidden !important;
}

.scrollToTop-button {
    border-radius: 30px !important;
    background-color: #083f88 !important;
    bottom: 20px !important;
    right: 20px !important;
}

.scrollToTop-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotate(90deg);
    font-size: 25px;
    margin-right: 0.1rem;
    margin-top: -0.35rem;
    letter-spacing: -6px;
}

.propertyInfoFormBody {
    position: relative;
}

.propertyInfoFormBody .blg-col-0 {
    display: none;
}

.propertyInfoFormBody h6 {
    font-weight: 700;
}

.propertyInfoFormBody .consent-font {
    font-size: 12px;
}

.propertyInfoFormBody .checkIConsent .form-check-label {
    font-weight: 700;
}

.propertyInfoFormBody .blg-row-2 {
    margin-top: -8px;
}

.propertyInfoFormBody h4 {
    font-size: 18px;
}

.propertyInfoFormBody .btn-blg,
.propertyInfoFormBody .btn-start {
    font-size: 13px;
}

@media screen and (max-width: 991.9px) {
    .navbarTransition {
        transform: translateY(-100%) !important;
        transition: all .3s ease-in !important;
    }
}